import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import APIError from './error';
import inject from './inject';

const isJSONOnly = (object) => {
  if (!object) return false;

  return object instanceof Object && !(object instanceof File);
};

const parseToValidParams = (object) => {
  const isNotNullish = (val) => val !== null && val !== undefined && val !== '';
  const removeNullishKeys = (rawObject) =>
    Object.fromEntries(
      Object.entries(rawObject).filter(([, value]) => isNotNullish(value))
    );

  const params = { ...object };

  if (!object) return params;

  if (isNotNullish(params.page)) {
    params.page += 1;
  }

  if (isNotNullish(params.per_page)) {
    params['per-page'] = params.per_page;
    delete params.per_page;
  }

  return removeNullishKeys(params);
};

const extractHeaders = (rawBody) => {
  if (!rawBody) return {};

  const { headers, data, ...body } = rawBody;

  return {
    body,
    headers,
    data
  };
};

class API {
  constructor(props = {}) {
    const Instance = axios.create({
      baseURL: props.baseURL
    });

    this.instance = Instance;

    inject(Instance);
  }

  async axios(method, url, rawBody, config = {}) {
    const axiosMethod = method.toLowerCase();
    const { body, headers, data } = extractHeaders(rawBody);

    const parsedBody = isJSONOnly(body)
      ? snakecaseKeys(body, {
          deep: true,
          exclude: ['headers']
        })
      : body;

    const params = parseToValidParams(parsedBody);
    let errors = {};

    try {
      const response = await this.instance[axiosMethod](
        url,
        axiosMethod === 'get'
          ? { params, headers, data, ...config }
          : parsedBody,
        config
      );

      if (response.status === 204) return {};
      if (response.status === 200 || response.status === 201) {
        return camelcaseKeys(
          (config.getRaw ? response : response.data?.data ?? response.data) ||
            {},
          { deep: true }
        );
      }

      const responseBody = response.data;

      errors = {
        ...responseBody
      };
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error.response);
      }

      if (!error.response?.data.success) {
        if (process.env.NODE_ENV !== 'production') {
          console.warn('Most like a NetworkError');
        }

        if (!error.response) {
          errors = {
            global:
              'Hubo un error, compruebe su conexión a internet e intente de nuevo.',
            status: 'NetworkError'
          };
        } else if (error.response.status === 404) {
          errors = {
            global: 'Este recurso no existe',
            status: error.response.status
          };
        } else {
          errors = {
            global:
              error.response.data.data.message ??
              'Ha ocurrido un error, estamos trabajando para resolverlo. Intente mas tarde.',
            status: error.response.status
          };
        }
      }
    }

    throw new APIError(errors);
  }
}

export default API;
