import React, {
  useRef,
  useState
} from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  SvgIcon,
  ListItemIcon,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';
import { LogOut } from 'react-feather';
import { useStyles } from './styles';

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user, logout } = useSelector((store) => ({
    user: store.user,
    logout: dispatch.user.logout,
  }));
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      handleClose();
      await logout();
      history.push('/login');
    } catch (error) {
      enqueueSnackbar(error.errors.global, {
        variant: 'error'
      });
    } finally {
      setLoading(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user.photo}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {`${user.name} ${user.lastName}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout} disabled={loading}>
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <LogOut />
            </SvgIcon>
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Salir
          </Typography>

          {loading && (
            <ListItemSecondaryAction>
              <CircularProgress color="primary" size={20} />
            </ListItemSecondaryAction>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
