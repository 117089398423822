import dayjs from 'dayjs';

/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const CURRENCIES = {
  USD: {
    lang: 'en-US',
    symbol: 'USD',
    value: 'usd'
  },
  LOCAL: {
    lang: 'en-US',
    symbol: 'Moneda local',
    value: 'local'
  }
};

export const DISCOUNTS_TYPES = [
  { label: 'Porcentaje', value: 'percent' },
  { label: 'Valor', value: 'value' }
];

export const DISCOUNTS_CATEGORIES = {
  COMMERCIAL: { label: 'Comercial', value: 'commercial' },
  FINANCIAL: { label: 'Financiero', value: 'financial' },
  REWARDED: { label: 'Bonificación', value: 'rewarded' }
};

export const GRID_SIZE = {
  desktop: {
    sm: 12,
    md: 6,
    lg: 6
  },
  tablet: {
    sm: 12,
    md: 6,
    lg: 6
  },
  mobile: {
    sm: 12,
    md: 12,
    lg: 12
  }
};

export const QUOTA_TYPES = [
  { id: 1, value: 1, label: '1 cuota' },
  { id: 2, value: 3, label: '3 cuotas' },
  { id: 3, value: 6, label: '6 cuotas' },
  { id: 4, value: 10, label: '10 cuotas' },
  { id: 5, value: 12, label: '12 cuotas' }
];

export const ARR_REPORT_TYPE = [
  { id: 1, value: 1, label: 'Por año' },
  { id: 2, value: 2, label: 'Por institución' },
  { id: 3, value: 3, label: 'Por país' }
];

export const YEARS = Array(100)
  .fill()
  .map((year, idx) => {
    const currentYear = dayjs().year();

    return {
      id: currentYear - idx,
      value: currentYear - idx,
      label: currentYear - idx
    };
  });

export const MONTHS = [
  { id: 1, label: 'Enero', value: 1 },
  { id: 2, label: 'Febrero', value: 2 },
  { id: 3, label: 'Marzo', value: 3 },
  { id: 4, label: 'Abril', value: 4 },
  { id: 5, label: 'Mayo', value: 5 },
  { id: 6, label: 'Junio', value: 6 },
  { id: 7, label: 'Julio', value: 7 },
  { id: 8, label: 'Agosto', value: 8 },
  { id: 9, label: 'Septiembre', value: 9 },
  { id: 10, label: 'Octubre', value: 10 },
  { id: 11, label: 'Noviembre', value: 11 },
  { id: 12, label: 'Diciembre', value: 12 }
];

export const EXTERNAL_ACCOUNTING_TOOLS = [
  { id: 1, value: 'colppy', label: 'Colppy' },
  { id: 2, value: 'quick-book', label: 'QuickBooks' }
];

export const BOX_MAX_WIDTH = 1150;
export const SMALL_BOX_MAX_WIDTH = 620;
export const TABLE_MIN_WIDTH = 950;

export const PUBLIC_ROUTES = ['guest', 'login'];
