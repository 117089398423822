
import JWT from 'jwt-decode';
import authService from 'src/services/auth';

const effects = () => ({
  async logout() {
    await authService.logout();
    await this.cleanUser();
  },

  async loginWithEmailAndPassword({ username, password, countryId = '346' }) {
    const { token } = await authService.loginWithEmailAndPassword({
      username,
      password,
      countryId
    });

    await this.loginFromSIS(token);
  },

  async loginFromSIS(token) {
    await this.setUserMinimumData({ apiKey: token });
    await this.getRequiredUserData();
  },

  async getRequiredUserData(...[, store]) {
    const { apiKey, id: userId } = store.user;

    let id = userId;

    if (!id) {
      const { uid } = JWT(apiKey);
      id = uid;
    }

    await this.getUserInfo(id);
  },

  async getUserInfo(uid) {
    const user = await authService.getUserInformation(uid);
    await this.setUserData(user);
  },
});

export default effects;
