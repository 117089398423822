import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

const Logo = ({ className, variant = 'default', ...props }) => {
  const classes = useStyles();
  const defaultLogo = '/static/logo.png';
  const loginLogo = '/static/backoffice-logo.png';
  const logoSrc = variant === 'login' ? loginLogo : defaultLogo;

  return (
    <img
      className={clsx(classes.root, className)}
      alt="Logo"
      src={logoSrc}
      {...props}
    />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['login', 'default']),
};

export default Logo;
