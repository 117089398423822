import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {ErrorBoundary as ErrorBoundaryWrapper } from 'react-error-boundary'


import ErrorFallback from './Fallback';

const ErrorBoundary = ({ children }) => {
  const history = useHistory();

  const handleReset = () => {
    history.push('/billing');
  };

  return (
    <ErrorBoundaryWrapper
      FallbackComponent={ErrorFallback}
      onReset={handleReset}
    >
      {children}
    </ErrorBoundaryWrapper>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
