import state from './state';
import reducers from './reducers';
import effects from './effects';

export const user = {
  state,
  reducers,
  effects,
};

export default user;
