import store from 'src/store';
import JWT from 'jwt-decode';
import authService from 'src/services/auth';
import { PUBLIC_ROUTES } from 'src/constants';

const inject = (Instance) => {
  Instance.interceptors.request.use((config) => {
    const { user } = store.getState();
    const exclude = config.url.includes('blendedstorageproduction');

    if (user.apiKey && !config.headers.Authorization && !exclude) {
      config.headers.Authorization = `Bearer ${user.apiKey}`;
    }

    return config;
  });

  Instance.interceptors.response.use(null, (error) => {
    const { user } = store.getState();
    const decoded = JWT(user.apiKey);
    const rolesAllowed = [
      'blended-financial',
      'blended-back-office',
      'blended-admin'
    ];
    const isAllowed = decoded.roles.some((role) => rolesAllowed.includes(role));
    const isTokenExpired = authService.isValidToken(user.apikey);
    const isPublicRoute = PUBLIC_ROUTES.some((publicRoute) =>
      window.location.pathname.includes(publicRoute)
    );

    if (isPublicRoute) {
      return Promise.resolve();
    }

    if (!isAllowed) {
      return window.location.replace('/login?rejected=role');
    }

    if (isTokenExpired) {
      return window.location.replace('/login?rejected=token');
    }

    return Promise.reject(error);
  });
};

export default inject;
